import { useState } from 'react'
import { Layout, Menu, Row, Col, Button } from 'antd';
const { Header } = Layout;
import { route as makeUrl } from 'themes/route'
import { MenuFoldOutlined, } from '@ant-design/icons';
import MenuMobie from "./MenuMobie";
import useScroll from 'themes/hooks/Scroll'
import Link from 'next/link'
import useBaseHooks from 'themes/hooks/BaseHooks';
import { i18n } from 'themes/modules/I18n'

const MenuPC = () => {
    const { t } = useBaseHooks({ lang: ['menu'] })
    const [visibleSidebar, setVisibleSidebar] = useState(false)
    const [headerClass, setHeaderClass] = useState("transparent")
    const [lang, setLang] = useState('vi')


    useScroll(({ xOffset, yOffset }: any) => {
        if (yOffset == 0 && headerClass != "transparent") {
            setHeaderClass("transparent")
        }
        else if (headerClass != "whitebg") {
            setHeaderClass("whitebg")
        }
    })

    const onVisibleChange = (newState: boolean) => {
        setVisibleSidebar(newState)
    }

    const changeLanguage = (lang:any) => {
        i18n.changeLanguage(lang)
        setLang('en')
        // location.reload();
    }
    return <>
        <Layout>
            <Header>
                <div id="topProMqn">
                    <div className="headerTopProMqnMain">
                        <Row>
                            <Col lg={22} xl={22}>
                                <Row>
                                    <div className="itemAdress">
                                        <img src="/static/img/placeholdersWhite.svg" alt="dohu.vn" />
                                        <p>{t('common:home.footer.address')}</p>
                                    </div> 
                                    <div className="itemAdress">
                                        <img src="/static/img/phoneW.svg" alt="dohu.vn" />
                                        <p>(+84)24-6291-9536 </p>
                                    </div>
                                </Row>
                            </Col>
                            <Col lg={2} xl={2}>
                                <a>
                                    <img onClick={() => changeLanguage('vi')}
                                        src="/static/img/meaning-of-vietnam-flag.jpg" alt="dohu.vn" className="IconLang"
                                    />
                                </a>
                                <a>
                                    <img
                                        onClick={() => changeLanguage('en')}
                                        src="/static/img/Flag_of_the_United_Kingdom.svg" alt="dohu.vn" className="IconLang"
                                    />
                                </a>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div id="meuMainPC">
                    <div className="header">
                        <div className={`menuPc ${headerClass}`}>
                            <div className="container">
                                <Row>
                                    <Col lg={7} xl={8}>
                                        <div className="logo">
                                            <Link {...makeUrl("frontend.home.index")}><img src="/static/img/logo.png" alt="dohu.vn" />
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col lg={17} xl={16}>
                                        <div className="menuMainMqn">
                                            <Menu mode="horizontal">
                                            <Menu.Item key="1">
                                                <Link href="#bannerHoverChange">{t('common:home.menu.platform')}</Link>
                                            </Menu.Item>
                                            <Menu.Item key="2">
                                                <Link {...makeUrl("frontend.home.campaign")}>{t('common:home.menu.targetCustomer')}</Link>
                                            </Menu.Item>
                                            <Menu.Item key="3">
                                                <Link {...makeUrl("frontend.home.location")}>{t('common:home.menu.inventory')}</Link>
                                            </Menu.Item>
                                            <Menu.Item key="3">
                                                <Link {...makeUrl("frontend.home.product")}>{t('common:home.menu.product')}</Link>
                                            </Menu.Item>
                                            <Menu.Item key="5">
                                                <Link {...makeUrl("frontend.home.blog")}>{t('common:home.menu.blog')}</Link>
                                            </Menu.Item>
                                            <Menu.Item key="6">
                                                <Link href="#contactForm"><a>{t('common:home.menu.contactUs')}</a></Link>
                                            </Menu.Item>
                                            <Menu.Item key="7">
                                                <Link href="#contactForm"><a>{t('common:home.menu.owner')}</a></Link>
                                            </Menu.Item>
                                            <Menu.Item key="8">
                                                <Link {...makeUrl("frontend.buyer.login")}><Button className="butnLogin">{t('common:home.menu.login')}</Button></Link>
                                            </Menu.Item>
                                                {/*
                                                  *<Menu.Item key="8">
                                                  *    <Link {...makeUrl("frontend.buyer.register")}><Button className="butnLogin">REGISTER</Button></Link>
                                                  *</Menu.Item>
                                                  */}
                                            </Menu>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    {/* Back mobie */}
                    <div className="backgroundMenuMobile">
                        <MenuMobie visible={visibleSidebar} onVisibleChange={onVisibleChange} />
                        <Row>
                            <Col xs={20} md={22} lg={22} xl={22}>
                                <div className="logo">
                                   <Link {...makeUrl("frontend.home.index")}><img src="/static/img/logo.png" style={{ height: 50 }} alt="dohu.vn"/></Link>
                                </div>
                            </Col>
                            <Col xs={4} md={2} lg={2} xl={2}>
                                <Button onClick={() => onVisibleChange(!visibleSidebar)} ><MenuFoldOutlined /></Button>
                            </Col>
                        </Row>
                    </div>
                    {/* End menu mobie */}
                </div>
            </Header>
        </Layout>
    </>
}


export default MenuPC
